<template>
    <div>
        <Navbar page="Vulnerabilidades" link="/cadastros" nameLink="Cadastros" link_2="/cadastros/riscos" nameLink_2="Gestão de riscos" />
        <div class="mx-4 my-4 md:mx-8 md:my-8">
            <div class="mt-5 md:mt-0 md:col-span-2">
                <form action="#" method="POST">
                    <div class="px-5 pb-4">
                        <div class="grid grid-cols-12 gap-6">
                            <div class="col-span-12 md:col-span-12">
                                <label for="name" class="block text-sm font-medium">Nome da Vulnerabilidade</label>
                                <input v-model="form.nome" type="text" name="nome" id="nome" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                            <div class="col-span-12 md:col-span-12">
                                <label for="descricao" class="block text-sm font-medium">Descrição</label>
                                <input v-model="form.descricao" type="text" name="descricao" id="descricao" class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                            </div> 
                            <div class="col-span-12 md:col-span-6">
                                <label for="ameaca" class="block text-sm font-medium">Ameaças</label>
                                <multiselect v-model="form.ameacas" :options="ameacas" :multiple="true" :close-on-select="false" :taggable="true" label="nome" trackBy="_id" id="vulnerabilidade" placeholder="selecione as vulnerabilidades" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-1"/>
                            </div>
                            <div class="col-span-12 md:col-span-6">
                                <label for="condutaMitigacao" class="block text-sm font-medium">Conduta de Mitigação</label>
                                <multiselect v-model="form.condutas" :options="condutas" :multiple="true" :close-on-select="false" :taggable="true" label="nome" trackBy="_id" id="condutaMitigacao" placeholder="selecione as condutas de mitigação" selectedLabel="selecionado" deselectLabel="Pressione Enter para remover" selectLabel="Pressione Enter para selecionar" class="mt-1"/>
                            </div>
                        </div>
                    </div>
                    
                    <div class="grid grid-cols-2">
                        <div class="px-5 py-3 text-left sm:px-6">
                            <button @click="$router.back()" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Voltar
                            </button>
                        </div>
                        <div class="px-5 py-3 text-right sm:px-6">
                            <button @click="save" type="button" class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-yellow-400" :style="{ 'background-color': $store.state.colorPrincipal, 'color': invertColor($store.state.colorPrincipal, true) }">
                                Salvar
                            </button>
                        </div>
                    </div>
                    
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            route: 'vulnerabilidades',
            form: {
                nome: '',
                descricao: '',
                ameacas: [],
                condutas: [],
            },
            ameacas: [],
            condutas: [],
        }
    },
    methods: {
        async save() {
            const method = this.form._id ? 'put' : 'post'

            const req = await this.$http[method](`/v1/${this.route}`, this.form);

            if (req.data.success) {
                this.$vToastify.success("Salvo com sucesso!");
                this.$router.back();
            } else {
                this.$vToastify.error(req.data.err);
            }
        }
    },
    async beforeMount() {
        const ameacasReq = await this.$http.post(`/v1/ameacas/list`,{ all: true});
        const condutasReq = await this.$http.post(`/v1/condutasmitigacao/list`,{ all: true});

        this.ameacas = ameacasReq.data.data;
        this.condutas = condutasReq.data.data;

        const id = this.$route.params.id;

        if (id) {
            const req = await this.$http.get(`/v1/${this.route}/${id}`);
            this.form = req.data;
        }
    },
}
</script>